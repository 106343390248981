import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import ContactPerson from "../../../components/contactPerson";
import Testimonial from "../../../components/testimonial";
import { GatsbyImage } from "gatsby-plugin-image";
import { PartnersGrid } from "../../../components/partnersGrid";

import service_strategy from "../../../assets/images/services/strategy.svg";
import service_cms from "../../../assets/images/services/cms.svg";
import service_design from "../../../assets/images/services/design.svg";
import service_frontend from "../../../assets/images/services/frontend.svg";
import service_backend from "../../../assets/images/services/backend.svg";
import service_projectmanagement from "../../../assets/images/services/projectmanagement.svg";

import generali from "../../../assets/images/logos/svg/generali.svg";
import dak_gesundheit from "../../../assets/images/logos/svg/dak-gesundheit.svg";
import tk from "../../../assets/images/logos/svg/tk.svg";
import eppendorf from "../../../assets/images/logos/svg/eppendorf.svg";
import swp from "../../../assets/images/logos/svg/swp.svg";
import cosmos from "../../../assets/images/logos/svg/cosmos.svg";
import hansemerkur_green from "../../../assets/images/logos/svg/hansemerkur_green.svg";
import bgw_green from "../../../assets/images/logos/svg/bgw_green.svg";
import haspa_green from "../../../assets/images/logos/svg/haspa_green.svg";

const Serviceportfolio = () => {
  const images = useStaticQuery(graphql`
    {
      dirk: file(relativePath: { eq: "profiles/dirk-schroedter.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const Testimonials = [
    {
      image: hansemerkur_green,
      name: "Katrin Menzel",
      position: "Senior Online Marketing Manager at HanseMerkur",
      text: "Implemented using Formcentric, the Insurance Finder is an impressive tool that offers our customers an easy way to find exactly the information that they are looking for. The options it gives us to develop the website in-house also strengthens the presence of HanseMerkur online — both today and for the future.",
      linkTo: "hansemerkur",
    },
    {
      image: bgw_green,
      name: "Oliver Willhöft",
      position: "Head of Software Technologies at BGW",
      text: "This stylish, contemporary design really makes the website shine. And I’m certain that the new technological approach and automation options are going to be a real help in the future, as we strive to become more responsive and more flexible.",
      linkTo: "bgw",
    },
    {
      image: haspa_green,
      name: "Peter Hußmann",
      position: "IT and Staffing Unit at Haspa",
      text: "From design or new functionality to maintenance of our content management system—with Monday Consulting we have a trusted partner who speaks our language, implements our requirements exactly as needed, and takes an innovative and creative approach to achieving optimum solutions.",
      linkTo: "haspa",
    },
  ];

  return (
    <Layout lang="en" langPath="nav.serviceportfolio.link">
      <Seo
        title="Full service for your projects – Monday Consulting"
        description="We are ready to tackle any challenge and make sure your project makes the grade. Offering an extensive portfolio that covers design, consulting, development, integration and project management, we strive to provide an inspiring mix of domain expertise plus supportive teamwork."
        keywords="Full service, content management systems, design, frontend, backend, infrastructure, project management, strategy, support, CoreMedia, FirstSpirt"
      />
      <div className="parallax-translate bg-shape-circle"></div>

      <div className="container mt-4 mb-4 mb-md-5">
        <div className="row">
          <div className="col-12">
            <h1 className="h5 text-color-monday-green">
              Our portfolio of services
            </h1>
            <h2 className="text-size-2 text-size-md-1">
              Our services at a glance
            </h2>
            <p>
              From monoliths to microservices, hosted in your own data center or
              in the cloud, our consultants and developers work remotely or on
              site, leveraging the latest technologies for your perfect
              solution. While also seeking to maximise the security,
              availability and performance of your applications.
            </p>
            <p>
              Our creative teams are ready to tackle any challenge and make sure
              your project makes the grade. And our project managers take an
              agile or conventional approach (as appropriate) to provide you
              with always-on support. After go-live, our Support Team provides
              you with advice and assistance going forward.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mb-5 mb-md-6 reveal">
          <div className="col-12 mb-3 mb-md-0 col-md-5 bg-size-contain bg-shape-cross">
            <img
              className="w-100"
              src={service_strategy}
              alt="Charting a safe course"
            />
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Digital strategy & conception
            </h2>
            <h3>Charting a safe course</h3>
            <p>
              Whatever the challenges that lie ahead, each project starts with a
              mature digital strategy and a well-designed blueprint for your IT.
              We draw on our wealth of expertise to address your exact
              requirements. From choosing a CMS to a new system architecture, we
              future-proof the approach taken and develop the optimum IT road
              map for your company.
            </p>
            <Link to="/en/services/strategy-conception" className="btn mt-1">
              Digital strategy & conception
            </Link>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 flex-md-row-reverse reveal">
          <div className="col-12 mb-3 mb-md-0 col-md-5 bg-size-contain bg-shape-square">
            <img
              className="w-100"
              src={service_cms}
              alt="Building the bedrock of your success"
            />
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Content management systems
            </h2>
            <h3>Building the bedrock of your success</h3>
            <p>
              We use the CoreMedia and FirstSpirit platforms to construct
              comprehensive enterprise solutions that map out complex structures
              and offer industry-leading flexibility. Lightweight alternatives
              such as Strapi also benefit from the application of our long
              experience: this headless CMS utilizes a modern, flexible approach
              to offer plenty of room for individualization.
            </p>
            <Link
              to="/en/services/content-management-systems"
              className="btn mt-1"
            >
              Content management systems
            </Link>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 reveal">
          <div className="col-12 mb-3 mb-md-0 col-md-5 bg-size-contain bg-shape-circle">
            <img
              className="w-100"
              src={service_design}
              alt="Letting your design shine"
            />
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">Creative web design</h2>
            <h3>Letting your design shine</h3>
            <p>
              Our creative design experts give your web presence poise and
              polish. Your website’s new look is not only visually impressive
              but is also driven by a set of perfectly tuned processes under the
              hood. Our design approach keeps an eye on development, making the
              most of the latest techniques to achieve the best-possible result
              for your content across all channels.
            </p>
            <Link to="/en/services/creative-design" className="btn mt-1">
              Creative web design
            </Link>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 flex-md-row-reverse reveal">
          <div className="col-12 mb-3 mb-md-0 col-md-5 bg-size-contain bg-shape-diamond">
            <img
              className="w-100"
              src={service_frontend}
              alt="Enriching your digital customer relationships"
            />
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Frontend & user experience
            </h2>
            <h3>Enriching your digital customer relationships</h3>
            <p>
              The websites we develop are not only appealing but also
              functional, fast and failure-tolerant. As adept practitioners in
              the most advanced languages and frameworks, we deploy these to
              accelerate project completion while optimizing the user
              experience. This superior website performance gives you the
              benefit of satisfied customers while boosting your search engine
              rankings.
            </p>
            <Link
              to="/en/services/frontend-user-experience"
              className="btn mt-1"
            >
              Frontend & user experience
            </Link>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 reveal">
          <div className="col-12 mb-3 mb-md-0 col-md-5 bg-size-contain bg-shape-dots">
            <img
              className="w-100"
              src={service_backend}
              alt="Keeping you ahead going forward"
            />
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Backend & infrastructure
            </h2>
            <h3>Keeping you ahead going forward</h3>
            <p>
              At Monday, we focus on developing new and advanced
              infrastructures. Our experts know how to deploy leading
              technologies to give you an advanced and high-performance
              architecture that is also easy to maintain. By using container
              architectures and microservices, we shorten the development cycle,
              speed up deployment, and provide prompt and responsive
              enhancements.
            </p>
            <Link to="/en/services/backend-infrastructure" className="btn mt-1">
              Backend & infrastructure
            </Link>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 flex-md-row-reverse reveal">
          <div className="col-12 mb-3 mb-md-0 col-md-5 bg-size-contain bg-shape-vertigo">
            <img
              className="w-100"
              src={service_projectmanagement}
              alt="By your side, whatever the future holds"
            />
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Project management & support
            </h2>
            <h3>By your side, whatever the future holds</h3>
            <p>
              Our certified project managers provide you with individual advice
              and assistance during your entire project. We apply the principle
              of continuous process improvement to guarantee your project
              outcomes. This achieves optimum project closure as well as the
              ongoing success of your strategy. First-class aftersales support
              from our Customer Care Helpdesk is always just a phone call away.
            </p>
            <Link
              to="/en/services/project-management-support"
              className="btn mt-1"
            >
              Project management & support
            </Link>
          </div>
        </div>
      </div>

      <PartnersGrid
        title="Specialization for your success"
        teaser="With our strong partnerships, we create accessible and individual experiences of tomorrow."
      />

      <div
        className="content-container vertical-spacing bg-monday-green bg-pattern-vertigo text-color-white text-center parallax-background"
        data-spacing-bottom="testimonials"
      >
        <div className="container pt-4 pt-md-5 pb-3">
          <div className="row mb-3">
            <div className="col-12 d-flex flex-column align-items-center">
              <h2 className="mb-3">
                These companies have moved to Monday Consulting
              </h2>
              <p>
                Companies in a wide range of industries use our tailor-made and
                future-proof solutions to secure success in the market by
                optimizing internal processes and improving their customer
                dialog. No two cases are the same — a fact of which we are
                particularly proud.
              </p>
              <Link className="btn" to="/en/references">
                Our case studies
              </Link>
            </div>
          </div>
          <div className="row mt-4 mb-2 align-items-center justify-content-center">
            <div className="p-2 flex-grow-0 mb-2">
              <img src={eppendorf} alt="Eppendorf Group" height="30px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={dak_gesundheit} alt="GDV" height="50px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={cosmos} alt="CosmosDirekt" height="70px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={tk} alt="Techniker Krankenkasse" height="50px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={swp} alt="Stadtwerke Potsdam" height="50px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={generali} alt="Generali" height="50px" />
            </div>
          </div>
        </div>
      </div>

      <div
        id="testimonials"
        className="container vertical-offset mb-5 mb-md-6 px-0 px-md-3"
        data-offset="50%"
      >
        <div className="swiper-container reveal">
          <div className="swiper-wrapper">
            {Testimonials.map((item, i) => (
              <Testimonial key={i} {...item} />
            ))}
          </div>
          <div className="swiper-pagination d-xl-none"></div>
        </div>
      </div>

      <ContactPerson
        headline="How can we help you today?"
        text="After browsing our impressive portfolio of services, why not contact us to find out more? Advice and assistance is just a phone call away."
        contactEmail="business@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Dirk Schrödter, Chief Executive"
      >
        <GatsbyImage
          image={images.dirk.childImageSharp.gatsbyImageData}
          alt="Dirk Schrödter"
        />
      </ContactPerson>
    </Layout>
  );
};

export default Serviceportfolio;
